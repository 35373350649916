import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "openshift-cas-quickstart released",
  "date": "2014-09-19T15:21:19-05:00",
  "tags": ["java", "security", "sso"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Today I’ve released the initial version of openshift-cas-quickstart. The project demostrate how to deploy Apereo CAS (Central Authentication Service), a single-sign-on solution onto OpenShift Online. Client side demo app(s) coming soon.`}</p>
    <p>{`For more information about the quickstart, visit `}<a parentName="p" {...{
        "href": "https://github.com/adrianchia/openshift-cas-quickstart"
      }}>{`https://github.com/adrianchia/openshift-cas-quickstart`}</a></p>
    <p><strong parentName="p">{`Updated 09/23/2014`}</strong>{` - Client side app available on `}<a parentName="p" {...{
        "href": "https://github.com/adrianchia/java-jasig-cas-client-demo"
      }}>{`https://github.com/adrianchia/java-jasig-cas-client-demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      